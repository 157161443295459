/* eslint-disable unused-imports/no-unused-vars */

import { Temporal } from '@js-temporal/polyfill';

export enum Channel {
    // Travelnest Direct
    TRAVELNEST = 'travelnest.com',
}

export enum CancellationPreferenceTypes {
    FLEXIBLE = 'flexible',
    FLEXIBLE_NON_REFUNDABLE_V1 = 'flexible_non_refundable_v1',
    RELAXED = 'relaxed',
    MODERATE_NON_REFUNDABLE_V1 = 'moderate_non_refundable_v1',
    MODERATE = 'moderate',
    STRICT_NON_REFUNDABLE_V1 = 'strict_non_refundable_v1',
    STRICT = 'strict',
    SUPER_STRICT = 'super_strict',
	// Dec 24 policies in preparation for smart policies
	DEC_24_SUPER_STRICT = 'dec_24_super_strict',
	DEC_24_STRICT= 'dec_24_strict',
	DEC_24_STRICT_NON_REFUNDABLE = 'dec_24_strict_non_refundable',
	DEC_24_MODERATE = 'dec_24_moderate',
	DEC_24_MODERATE_NON_REFUNDABLE = 'dec_24_moderate_non_refundable',
	DEC_24_FLEXIBLE = 'dec_24_flexible',
	DEC_24_FLEXIBLE_NON_REFUNDABLE = 'dec_24_flexible_non_refundable',
	DEC_24_SUPER_FLEXIBLE = 'dec_24_super_flexible',
	DEC_24_SUPER_FLEXIBLE_NON_REFUNDABLE = 'dec_24_super_flexible_non_refundable'
}

export const CancellationPreferences = {
    [CancellationPreferenceTypes.SUPER_STRICT]: 'Super strict',
    [CancellationPreferenceTypes.STRICT_NON_REFUNDABLE_V1]: 'Strict',
    [CancellationPreferenceTypes.STRICT]: 'Strict',
    [CancellationPreferenceTypes.MODERATE_NON_REFUNDABLE_V1]: 'Moderate',
    [CancellationPreferenceTypes.MODERATE]: 'Moderate',
    [CancellationPreferenceTypes.RELAXED]: 'Relaxed',
    [CancellationPreferenceTypes.FLEXIBLE_NON_REFUNDABLE_V1]: 'Flexible',
    [CancellationPreferenceTypes.FLEXIBLE]: 'Flexible',
	// Dec 24 policies in preparation for smart policies
	[CancellationPreferenceTypes.DEC_24_SUPER_STRICT]: 'Super strict',
	[CancellationPreferenceTypes.DEC_24_STRICT]: 'Strict',
	[CancellationPreferenceTypes.DEC_24_STRICT_NON_REFUNDABLE]: 'Strict',
	[CancellationPreferenceTypes.DEC_24_MODERATE]: 'Moderate',
	[CancellationPreferenceTypes.DEC_24_MODERATE_NON_REFUNDABLE]: 'Moderate',
	[CancellationPreferenceTypes.DEC_24_FLEXIBLE]: 'Flexible',
	[CancellationPreferenceTypes.DEC_24_FLEXIBLE_NON_REFUNDABLE]: 'Flexible',
	[CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE]: 'Super flexible',
	[CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE_NON_REFUNDABLE]: 'Super flexible'
};

export const CancellationBookingMapping = {
    'travelnest_direct_flexible_v1': CancellationPreferenceTypes.FLEXIBLE,
    'travelnest_direct_relaxed_v1': CancellationPreferenceTypes.RELAXED,
    'travelnest_direct_moderate_v1': CancellationPreferenceTypes.MODERATE,
    'travelnest_direct_strict_v1': CancellationPreferenceTypes.STRICT,
    'travelnest_direct_super_strict_v1': CancellationPreferenceTypes.SUPER_STRICT,
	// Dec 24 policies in preparation for smart policies
	'travelnest_direct_dec_24_super_strict': CancellationPreferenceTypes.DEC_24_SUPER_STRICT,
	'travelnest_direct_dec_24_strict': CancellationPreferenceTypes.DEC_24_STRICT,
	'travelnest_direct_dec_24_strict_non_refundable': CancellationPreferenceTypes.DEC_24_STRICT_NON_REFUNDABLE,
	'travelnest_direct_dec_24_moderate': CancellationPreferenceTypes.DEC_24_MODERATE,
	'travelnest_direct_dec_24_moderate_non_refundable': CancellationPreferenceTypes.DEC_24_MODERATE_NON_REFUNDABLE,
	'travelnest_direct_dec_24_flexible': CancellationPreferenceTypes.DEC_24_FLEXIBLE,
	'travelnest_direct_dec_24_flexible_non_refundable': CancellationPreferenceTypes.DEC_24_FLEXIBLE_NON_REFUNDABLE,
	'travelnest_direct_dec_24_super_flexible': CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE,
	'travelnest_direct_dec_24_super_flexible_non_refundable': CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE_NON_REFUNDABLE
}

export const defaultPolicies = {
    [Channel.TRAVELNEST]: {
        id: Channel.TRAVELNEST,
        active: true,
        teesandcees: 'https://intercom.help/travelnest/en/articles/1910358-travelnest-terms-and-conditions',
        commission: 'default_rate',
        faqs: [
            {
                title: 'Where are the Travelnest terms and conditions?',
                text: 'You can read the terms and conditions here.',
                link: 'https://intercom.help/travelnest/en/articles/1910358-travelnest-terms-and-conditions'
            }
        ],
        policies: {
            [CancellationPreferenceTypes.SUPER_STRICT]: {
                name: 'cancellation_policy_direct_superstrict_v1',
                refund: [
                    {
                        amount: 0,
                        period: 'at any time',
                        days: 0
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.STRICT_NON_REFUNDABLE_V1]: {
                name: 'cancellation_policy_direct_strict_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 60 days before arrival',
                        days: 60
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.STRICT]: {
                name: 'cancellation_policy_direct_strict_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 60 days before arrival',
                        days: 60
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.MODERATE_NON_REFUNDABLE_V1]: {
                name: 'cancellation_policy_direct_moderate_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 30 days before arrival',
                        days: 30
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.MODERATE]: {
                name: 'cancellation_policy_direct_moderate_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 30 days before arrival',
                        days: 30
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.RELAXED]: {
                name: 'cancellation_policy_direct_relaxed_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 14 days before arrival',
                        days: 14
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.FLEXIBLE_NON_REFUNDABLE_V1]: {
                name: 'cancellation_policy_direct_flexible_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 7 days before arrival',
                        days: 7
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
            [CancellationPreferenceTypes.FLEXIBLE]: {
                name: 'cancellation_policy_direct_flexible_v1',
                refund: [
                    {
                        amount: 100,
                        period: 'up to 7 days before arrival',
                        days: 7
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
			[CancellationPreferenceTypes.DEC_24_SUPER_STRICT]: {
                name: 'travelnest_direct_dec_24_super_strict',
                refund: [
                    {
                        amount: 0,
                        period: 'at any time',
                        days: 0
                    }
                ],
                guestPays: [
                    {
                        amount: 100,
                        period: 'at time of booking'
                    }
                ],
                ownerPaid: [
                    {
                        period: 'within 3-10 days of guest arrival'
                    }
                ]
            },
			[CancellationPreferenceTypes.DEC_24_STRICT]: {
				name: 'travelnest_direct_dec_24_strict',
				refund: [
					{
						amount: 100,
						period: 'up to 60 days before arrival',
						days: 60
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_STRICT_NON_REFUNDABLE]: {
				name: 'travelnest_direct_dec_24_strict_non_refundable',
				refund: [
					{
						amount: 100,
						period: 'up to 60 days before arrival',
						days: 60
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_MODERATE]: {
				name: 'travelnest_direct_dec_24_moderate',
				refund: [
					{
						amount: 100,
						period: 'up to 30 days before arrival',
						days: 30
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				],
			},
			[CancellationPreferenceTypes.DEC_24_MODERATE_NON_REFUNDABLE]: {
				name: 'travelnest_direct_dec_24_moderate_non_refundable',
				refund: [
					{
						amount: 100,
						period: 'up to 30 days before arrival',
						days: 30
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_FLEXIBLE]: {
				name: 'travelnest_direct_dec_24_flexible',
				refund: [
					{
						amount: 100,
						period: 'up to 14 days before arrival',
						days: 14
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_FLEXIBLE_NON_REFUNDABLE]: {
				name: 'travelnest_direct_dec_24_flexible_non_refundable',
				refund: [
					{
						amount: 100,
						period: 'up to 14 days before arrival',
						days: 14
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE]: {
				name: 'travelnest_direct_dec_24_super_flexible',
				refund: [
					{
						amount: 100,
						period: 'up to 5 days before arrival',
						days: 5
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			},
			[CancellationPreferenceTypes.DEC_24_SUPER_FLEXIBLE_NON_REFUNDABLE]: {
				name: 'travelnest_direct_dec_24_super_flexible_non_refundable',
				refund: [
					{
						amount: 100,
						period: 'up to 5 days before arrival',
						days: 5
					}
				],
				guestPays: [
					{
						amount: 100,
						period: 'at time of booking'
					}
				],
				ownerPaid: [
					{
						period: 'within 3-10 days of guest arrival'
					}
				]
			}

        }
    }
}

const isCancellationRefundable = (policy: { days: number }, latestCancellationDate: Temporal.PlainDate, checkInDate: Temporal.PlainDate) => {
    const today = Temporal.Now.plainDateTimeISO();
    if (policy.days > 0 && Temporal.PlainDate.compare(checkInDate.toString(), latestCancellationDate.toString()) > 0 && Temporal.PlainDate.compare(latestCancellationDate, today) >= 0) {
        return true;
    }
    return false;
}

export const cancellationPolicyStrings = (cancellation_policy: CancellationPreferenceTypes, check_in: string, locale?: string) => {
    if (!check_in)
        return ['', '', ''];

    const checkInDate = Temporal.PlainDate.from(check_in);
    const policy = defaultPolicies[Channel.TRAVELNEST].policies[cancellation_policy].refund[0];
    const cancellationDate = Temporal.PlainDate.from(checkInDate).subtract({ days: policy.days });
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const isRefundable = isCancellationRefundable(policy, cancellationDate, checkInDate);

    const dateHightlightText = isRefundable ? `${cancellationDate.toLocaleString(locale, options as any)}.` : 'non-refundable.';

    const shortText = isRefundable ? 'Free cancellation until' : 'This reservation is';

    const footer = (!isRefundable || policy.amount === 0) ? 'Cancellations or no shows will be charged the full price.' : 'Cancellations after the free cancellation window has passed will receive no refund along with no shows.';

    return [shortText, dateHightlightText, footer];
}
